import {
  AdditiveBlending,
  BackSide,
  BufferAttribute,
  BufferGeometry,
  CircleGeometry,
  Color,
  CylinderGeometry,
  LineBasicMaterial,
  LineSegments,
  MathUtils,
  Mesh,
  MeshBasicMaterial,
  MeshLambertMaterial,
  Object3D,
  PerspectiveCamera,
  PointLight,
  Points,
  PointsMaterial,
  RepeatWrapping,
  Scene,
  SpotLight,
  Texture,
  TextureLoader,
  Vector3,
  WebGLRenderer,
  WebGLRendererParameters
} from 'three'

const _w = window
const _b = document.body
const _d = document.documentElement
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type HyperclassSignature =
  | string
  | number
  | Object3D
  | Record<string, number>
  | null
  | boolean
  | WarpStage
  | (() => void)
  | BufferGeometry
  | Texture

class Warplines {
  [k: string]: HyperclassSignature
  count: number
  acceleration: number
  accelerationTail: number
  hyperDelay: number
  warplinesPos: number

  geometry: BufferGeometry | null
  group: Object3D | null

  ready: boolean
  hyper: boolean
  stage: WarpStage

  constructor(stage: WarpStage) {
    this.onInit = this.onInit.bind(this)
    this.onFrame = this.onFrame.bind(this)
    this.create = this.create.bind(this)
    this.count = 8000
    this.acceleration = 0.03
    this.accelerationTail = 0.005
    this.hyperDelay = 1000
    this.warplinesPos = 100
    this.geometry = null
    this.group = null

    this.ready = false
    this.stage = stage
  }

  onInit() {
    this.group = new Object3D()

    this.create()
    this.ready = true
  }

  onFrame() {
    if (this.ready && this.hyper) {
      for (let i = 0; i < this.count; i++) {
        const positions = this.geometry!.getAttribute('position').array as number[]
        const velocities = this.geometry!.getAttribute('velocity').array as number[]
        const vStart = 2 * i
        const vEnd = 2 * i + 1
        const zStart = 6 * i + 2
        const zEnd = 6 * i + 5

        velocities[vStart] += this.acceleration // bump up the velocity by the acceleration amount
        velocities[vEnd] += this.acceleration - this.accelerationTail

        positions[zStart] += velocities[vStart] //z
        positions[zEnd] += velocities[vEnd] //z end

        if (positions[zEnd] > this.warplinesPos) {
          const z = MathUtils.randFloatSpread(500)
          positions[zStart] = z
          positions[zEnd] = z
          velocities[vStart] = 0
          velocities[vEnd] = 0
        }
      }

      this.geometry!.getAttribute('position').needsUpdate = true
    }
  }

  create() {
    this.geometry = new BufferGeometry()
    this.geometry.setAttribute('position', new BufferAttribute(new Float32Array(6 * this.count), 3))
    this.geometry.setAttribute('velocity', new BufferAttribute(new Float32Array(2 * this.count), 1))

    for (let i = 0; i < this.count; i++) {
      const x = MathUtils.randFloatSpread(400)
      const y = MathUtils.randFloatSpread(200)
      const z = MathUtils.randFloatSpread(500)
      const xx = x
      const yy = y
      const zz = z
      const pa = this.geometry.getAttribute('position').array as number[]
      const va = this.geometry.getAttribute('velocity').array as number[]
      //line start
      const idxOffset = i * 6
      pa[idxOffset] = x
      pa[idxOffset + 1] = y
      pa[idxOffset + 2] = z
      //line end
      pa[idxOffset + 3] = xx
      pa[idxOffset + 4] = yy
      pa[idxOffset + 5] = zz

      va[2 * i] = va[2 * i + 1] = 0
    }

    const mat = new LineBasicMaterial({color: 0xffffff})
    const lines = new LineSegments(this.geometry, mat)
    this.group!.add(lines)
  }

  hyperStart() {
    this.hyper = true
    this.stage.scene.add(this.group!)
  }

  hyperStop() {
    setTimeout(() => {
      this.hyper = false
      this.stage.scene.remove(this.group!)
    }, this.hyperDelay)
  }
}

class Wormhole {
  [k: string]: HyperclassSignature
  ease: number
  hyperDelay: number
  hype: {
    normalize: number
    scale: number
    speed: number
  }
  texture: Texture | null
  geometry: BufferGeometry | null
  group: Object3D | null

  ready: boolean
  hyper: boolean
  stage: WarpStage

  constructor(stage: WarpStage) {
    this.onInit = this.onInit.bind(this)
    this.onFrame = this.onFrame.bind(this)
    this.create = this.create.bind(this)
    this.group = null
    this.texture = null

    this.ease = 10
    this.hyperDelay = 1000
    this.hype = {
      normalize: 0.001,
      scale: 0.15,
      speed: 6.26
    }

    this.ready = false
    this.hyper = false
    this.stage = stage
  }

  onFrame() {
    if (!this.ready || !this.hyper || !this.texture) return
    const {hype, stage} = this
    this.texture.offset.y -= hype.normalize * hype.speed
    this.texture.needsUpdate = true

    this.group!.scale.set(hype.scale, hype.scale, 1)

    const {rotation, position} = stage.camera
    this.group!.rotation.set(rotation.x, rotation.y, rotation.z)
    this.group!.position.set(position.x, position.y, position.z)

    this.group!.rotation.z -= 0.008

    return
  }

  onInit() {
    this.create()
    this.ready = true
  }

  create() {
    this.group = new Object3D()
    this.group.position.z = this.stage.camera.position.z
    const loader = new TextureLoader()
    this.texture = loader.load('/images/modules/signup/launch_codes/wormhole.jpg')
    this.texture.wrapT = RepeatWrapping
    this.texture.wrapS = RepeatWrapping

    const geometry = new CylinderGeometry(100, 0, 300, 40, 40, true)
    const material = new MeshLambertMaterial({
      color: 0xffffff,
      opacity: 1,
      map: this.texture,
      blending: AdditiveBlending,
      side: BackSide,
      transparent: true,
      depthTest: true
    })

    const color = new Color()
    color.setHSL(Math.random(), 1, 0.8)

    const light = new PointLight(color, 4, 100)
    light.position.set(0, 0, 0)

    const cylinder = new Mesh(geometry, material)
    cylinder.position.set(0, 0, 0)
    cylinder.rotation.x = Math.PI / 2

    this.group.add(cylinder)
    this.group.add(light)
  }

  hyperStart() {
    this.hyper = true
    setTimeout(() => {
      this.stage.scene.add(this.group!)
    }, this.hyperDelay)
  }

  hyperStop() {
    setTimeout(() => {
      this.hyper = false
    }, this.hyperDelay)
  }
}

class Starfield {
  [k: string]: HyperclassSignature
  distance: number
  destinationPos: number
  destinationSize: number
  hyperDistance: number
  hyperEnd: number
  size: number
  spread: number
  total: number

  ease: number
  move: {
    x: number
    y: number
    z: number
  }
  look: {
    x: number
    y: number
    z: number
  }
  group: Object3D | null

  ready: boolean
  hyper: boolean
  stage: WarpStage

  constructor(stage: WarpStage) {
    this.onInit = this.onInit.bind(this)
    this.onFrame = this.onFrame.bind(this)
    this.create = this.create.bind(this)
    this.total = 600
    this.spread = 2000
    this.size = 25
    this.destinationSize = 75

    this.distance = -3000
    this.destinationPos = 200

    this.hyperDistance = 5000
    this.hyperEnd = this.hyperDistance - this.distance - this.destinationPos
    this.ease = 15
    this.move = {x: 0, y: 0, z: this.distance}
    this.look = {x: 0, y: 0, z: 0}
    this.group = null

    this.ready = false
    this.hyper = false
    this.stage = stage
  }

  onInit() {
    this.group = new Object3D()
    this.group.position.set(this.move.x, this.move.y, this.move.z - this.hyperDistance)

    this.group.rotation.set(this.look.x, this.look.y, this.look.z)

    this.create()
    this.ready = true
  }

  onFrame() {
    if (this.ready && this.group) {
      this.group.position.z += (this.move.z - this.group.position.z) / this.ease
    }
  }

  // create new star field group
  create() {
    const geometry = new BufferGeometry()
    const loader = new TextureLoader()
    const texture = loader.load('/images/modules/signup/launch_codes/star.png')
    const material = new PointsMaterial({
      size: this.size,
      color: 0xffffff,
      opacity: 0.8,
      blending: AdditiveBlending,
      transparent: true,
      map: texture
    })
    const positions = new Float32Array((this.total + 1) * 3)

    const vertex = new Vector3()
    for (let i = 0; i < this.total; i++) {
      vertex.x = MathUtils.randFloatSpread(this.spread)
      vertex.y = MathUtils.randFloatSpread(this.spread) // this is the "distance" of the starfield
      vertex.z = MathUtils.randFloatSpread(this.hyperEnd)
      vertex.toArray(positions, i * 3)
    }
    geometry.setAttribute('position', new BufferAttribute(positions, 3))

    const stars = new Points(geometry, material)
    this.group!.add(stars)
  }

  createLandingStar() {
    const loader = new TextureLoader()
    const bgTexture = loader.load('/images/modules/signup/launch_codes/star.png')

    const sphereGeo = new CircleGeometry(this.destinationSize, 32)
    const sphereMat = new MeshBasicMaterial({
      color: 0xffffff,
      opacity: 1,
      blending: AdditiveBlending,
      transparent: false,
      map: bgTexture
    })

    const northStar = new Mesh(sphereGeo, sphereMat)
    northStar.position.set(0, 0, this.destinationPos)

    return northStar
  }

  hyperStart() {
    this.stage.scene.add(this.group!)

    this.move = {
      x: 0,
      y: 0,
      z: this.distance
    }
    this.group!.scale.set(0.75, 0.75, 1)
    this.ease += 100
    this.move.z -= this.hyperDistance
    this.hyper = true

    const northStar = this.createLandingStar()
    this.group!.add(northStar)
  }

  // on hyper stop
  hyperStop() {
    this.group!.scale.set(1, 1, 1.99)
    this.ease -= 100
    this.move.z += this.hyperEnd
    this.hyper = false
  }
}
class WarpStage {
  id: string
  width: number
  height: number
  ease: number
  move: {
    x: number
    y: number
    z: number
  }
  look: {
    x: number
    y: number
    z: number
  }

  objects: Array<Starfield | Wormhole | Warplines>
  camera: PerspectiveCamera
  renderer: WebGLRenderer
  light: SpotLight
  scene: Scene

  ready: boolean
  hyper: boolean

  constructor(id: string, opts: WebGLRendererParameters) {
    this.triggerEvent = this.triggerEvent.bind(this)
    this.drawFrame = this.drawFrame.bind(this)
    this.onResize = this.onResize.bind(this)
    this.init = this.init.bind(this)

    this.objects = [new Starfield(this), new Wormhole(this), new Warplines(this)]

    this.width = 0 // is set later onResize
    this.height = 0 // is set later onResize
    this.ease = 20
    this.move = {x: 0, y: 0, z: 200}
    this.look = {x: 0, y: 0, z: 0}

    this.id = id

    this.renderer = this.resolveRenderer(opts || {})
    this.camera = new PerspectiveCamera(60, window.innerWidth / window.innerHeight, 0.1, 10000)
    this.light = new SpotLight(0xffffff, 1, 3000, 0.4, 0.5, 1)

    this.scene = new Scene()

    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setClearColor(0x000000, 0)
    this.renderer.sortObjects = false // fixes starfield not rendering transparent

    this.camera.position.set(this.move.x, this.move.y, this.move.z)
    this.camera.rotation.set(this.look.x, this.look.y, this.look.z)
    this.camera.lookAt(this.scene.position)
    this.light.position.set(this.move.x, this.move.y, this.move.z)

    this.scene.add(this.light)

    this.ready = true
  }

  resolveRenderer(params: WebGLRendererParameters) {
    params = params || {}
    return new WebGLRenderer(params)
  }

  triggerEvent(name: string) {
    if (this.ready) {
      for (const o of this.objects) {
        if (typeof o[name] === 'function') {
          const fx = o[name] as () => void
          fx!.call(o)
        }
      }
    }
  }

  drawFrame() {
    if (this.ready) {
      this.triggerEvent('onFrame')

      this.renderer.render(this.scene, this.camera)
    }
    requestAnimationFrame(this.drawFrame)
  }

  screenInfo() {
    const screenWidth = Math.max(0, _w.innerWidth || _d.clientWidth || _b.clientWidth || 0)
    const screenHeight = Math.max(0, _w.innerHeight || _d.clientHeight || _b.clientHeight || 0)
    return {
      width: screenWidth,
      height: screenHeight,
      ratio: screenWidth / screenHeight,
      centerX: screenWidth / 2,
      centerY: screenHeight / 2
    }
  }

  onResize() {
    const info = this.screenInfo()
    this.width = info.width
    this.height = info.height

    if (this.ready) {
      this.renderer.setSize(this.width, this.height)
      this.camera.aspect = this.width / this.height
      this.camera.updateProjectionMatrix()
    }
  }

  // start hyper travel
  hyperStart() {
    this.triggerEvent('hyperStart')
  }

  hyperStop() {
    this.triggerEvent('hyperStop')
  }

  init() {
    if (this.renderer && this.renderer.domElement) {
      this.renderer.domElement.setAttribute('id', this.id)
      document.querySelector('.js-signup-warp-bg')?.appendChild(this.renderer.domElement)
    }
    this.onResize()
    this.triggerEvent('onInit')
    this.drawFrame()
    /* eslint-disable-next-line github/prefer-observers */
    window.addEventListener('resize', () => this.onResize())
  }
}

const webglSupport = () => {
  try {
    const canvas = document.createElement('canvas')
    return !!(window.WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')))
  } catch (e) {
    return false
  }
}

export const stageSetup = () => {
  // check WebGL support - https://github.com/adolfintel/warpspeed

  if (webglSupport()) {
    return new WarpStage('warp-stage', {
      alpha: true,
      antialias: true,
      precision: 'mediump'
    })
  }
}

export default stageSetup
